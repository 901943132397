<template>


  <div class="output-controls">

    <!-- mobile sticky footer -->
    <div class="fixed-bottom navbar-dark d-md-none" style="background-color:#000000cc;z-index:1031">
      <!-- Big button is either save or update -->
      <div style="" class="d-flex flex-row flex-wrap p-1">
        <!-- <b-alert variant="warning" :show="blnDirty">You have unpublished changes.</b-alert> -->
        <!-- <b-badge variant="warning" v-show="blnDirty">Unpublished Changes</b-badge> -->
        <b-button v-show="showUpdate" class="m-1" style="" title="Update" variant="primary" size="lg" @click="showSaveRemixModal(true)" >
          <div class="d-flex flex-row align-items-center">
            <div><font-awesome-icon size="1x" icon="save"/></div>
            <div class="ml-2" style="font-size:0.5em;line-height:1.2em; text-align:left"><span style="font-size:1.4em; font-weight:800">Update</span><br/>this&nbsp;Remix</div>
          </div>
        </b-button>
        <b-button class="m-1" style="" title="Publish" variant="primary" size="lg" @click="showSaveRemixModal(false)" :disabled="!blnDirty">
          <div class="d-flex flex-row align-items-center">
            <div><font-awesome-icon size="1x" icon="arrow-circle-up"/></div>
            <div class="ml-2" style="font-size:0.5em;line-height:1.2em; text-align:left"><span style="font-size:1.4em; font-weight:800">Publish</span><br/>New&nbsp;Remix</div>
          </div>
        </b-button>
        <!-- <b-button class="mr-2 mb-2" style="" title="Publish" variant="primary" size="lg" v-b-modal.saveRemixModal :disabled="!blnDirty"><span style="font-size:1em; font-weight:800"><font-awesome-icon size="1x" icon="arrow-circle-up" />&nbsp;Publish</span> as New Remix</b-button><br/> -->
        <div style="" class="d-flex flex-row">
          <b-button variant="info" size="lg" class="m-1" title="Share Remix" v-b-modal.shareRemixModal><font-awesome-icon size="1x" icon="share-alt" /></b-button>
          <b-button variant="info" size="lg" class="m-1" title="Download Remix to your desktop" v-b-modal.downloadRemixModal><font-awesome-icon size="1x" icon="download" /></b-button>
          <b-button v-show="showPinRemix" :variant="(blnGalleryPrompt) ? 'success' : 'info'" size="lg" class="m-1" title="Pin Remix as Prompt for this Gallery" v-b-modal.pinRemixModal><font-awesome-icon size="1x" icon="thumbtack" /></b-button>
          <b-button v-show="showDelete" variant="warning" title="Delete Remix" size="lg" class="m-1" v-b-modal.deleteRemixModal><font-awesome-icon size="1x" icon="trash-alt" /></b-button>
        </div>
      </div>
    </div>


    <div class="d-none d-md-block" style="">
      <b-card
            class="mb-2"
            bg-variant="dark"
            border-variant="transparent"
          >
      <!-- Big button is either save or update -->
      <div style="" class="d-flex flex-row flex-wrap">
        <!-- <b-alert variant="warning" :show="blnDirty">You have unpublished changes.</b-alert> -->
        <!-- <b-badge variant="warning" v-show="blnDirty">Unpublished Changes</b-badge> -->
        <b-button v-show="showUpdate" class="mr-2 mb-2" style="" title="Update" variant="primary" size="lg" @click="showSaveRemixModal(true)" >
          <div class="d-flex flex-row align-items-center">
            <div><font-awesome-icon size="2x" icon="save"/></div>
            <div class="ml-2" style="font-size:0.8em;line-height:1.2em; text-align:left"><span style="font-size:1.2em; font-weight:800">Update</span><br/>this&nbsp;Remix</div>
          </div>
        </b-button>
        <b-button class="mr-2 mb-2" style="" title="Publish" variant="primary" size="lg" @click="showSaveRemixModal(false)" :disabled="!blnDirty">
          <div class="d-flex flex-row align-items-center">
            <div><font-awesome-icon size="2x" icon="arrow-circle-up"/></div>
            <div class="ml-2" style="font-size:0.8em;line-height:1.2em; text-align:left"><span style="font-size:1.2em; font-weight:800">Publish</span><br/>as&nbsp;New&nbsp;Remix</div>
          </div>
        </b-button>
        <!-- <b-button class="mr-2 mb-2" style="" title="Publish" variant="primary" size="lg" v-b-modal.saveRemixModal :disabled="!blnDirty"><span style="font-size:1em; font-weight:800"><font-awesome-icon size="1x" icon="arrow-circle-up" />&nbsp;Publish</span> as New Remix</b-button><br/> -->
        <div style="" class="d-flex flex-row">
          <b-button variant="info" size="lg" class="mr-2 mb-2" title="Share Remix" v-b-modal.shareRemixModal><font-awesome-icon size="1x" icon="share-alt" /></b-button>
          <b-button variant="info" size="lg" class="mr-2 mb-2" title="Download Remix to your desktop" v-b-modal.downloadRemixModal><font-awesome-icon size="1x" icon="download" /></b-button>
          <b-button v-show="showPinRemix" :variant="(blnGalleryPrompt) ? 'success' : 'info'" size="lg" class="mr-2 mb-2" title="Pin Remix as Prompt for this Gallery" v-b-modal.pinRemixModal><font-awesome-icon size="1x" icon="thumbtack" /></b-button>
          <b-button v-show="showDelete" variant="warning" title="Delete Remix" size="lg" class="mb-2" v-b-modal.deleteRemixModal><font-awesome-icon size="1x" icon="trash-alt" /></b-button>
        </div>
      </div>
      </b-card>
    </div>

    <!-- Canvas Placeholder -->
    <canvas id="myCanvas" class="d-none" ref="pngCanvas" :width="svgWidth" :height="svgHeight"></canvas>
    <canvas id="myThumbCanvas" class="d-none" ref="pngThumbCanvas" :width="svgThumbWidth" :height="svgThumbHeight"></canvas>

    <!-- DELETE modal -->
    <b-modal  
      id="deleteRemixModal"
      centered
      title="Delete remix"
      body-bg-variant="dark"
      header-bg-variant="dark"
      footer-bg-variant="dark"

      :ok-disabled="blnGalleryPrompt"
      ok-title="DELETE"
      cancel-title="CANCEL"
      ok-variant="danger"
      @ok="confirmDeleteRemix"
      >

      <p v-if="blnGalleryPrompt">
        This remix is currently pinned as the prompt for the Gallery. Please unpin it before deleting.</p>
      <p v-else>
        Are you sure you want delete this remix?
      </p>

    </b-modal>

    <!-- SHARE modal -->
    <b-modal  
      id="shareRemixModal"
      centered
      title="Share remix"
      body-bg-variant="dark"
      header-bg-variant="dark"
      footer-bg-variant="dark"
      ok-only
      ok-title="CLOSE"
      ok-variant="info">

      <!-- SHARE this Remix -->
      <!-- but only if there aren't any unsaved changes  -->
      <div class="card bg-dark" v-show="!blnImageError">
        <!-- <div class="card-body"> -->

          <div class="">
            <h4>Page Link</h4>

            <b-alert variant="warning" :show="blnDirty">Your Remix has unsaved changes which won't be reflected in your share!</b-alert>
            
            <b-input-group class="mt-3">
              <b-input-group-prepend is-text>
                <font-awesome-icon size="1x" fixed-width icon="link" />
              </b-input-group-prepend>
              <b-form-input v-model="shareLink"></b-form-input>
              <b-input-group-append>
                <b-button variant="info" 
                  v-clipboard:copy="shareLink"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError">{{(showCopiedToClipboard)?'Copied':'Copy'}}</b-button>
              </b-input-group-append>
            </b-input-group>

            
          </div>

          <div class="mt-4">
            <!-- <social-sharing :url="shareLink"
              :title="$route.meta.title"
              :description="$route.meta.description"
              quote="Remix this thinkery..."
              :hashtags="this.appSettings.socialHashtags"
              twitter-user="visualthinkery"
              inline-template>

              <b-button variant="primary" class="buttontext"><network network="twitter"><font-awesome-icon size="3x" fixed-width :icon="['fab', 'x-twitter']" /><br/>TWEET</network></b-button>
            </social-sharing> -->

            <!-- social sharing -->
            <h4 class="mb-0">Share on Socials</h4>
            <p class="small">(opens pop-up window)</p>
            <div class="d-flex">
              <div class="" style="text-align: center;"><b-button variant="primary" class="" target="bsky" :href="'https://bsky.app/intent/compose?text=' + shareText + '%20' + shareLink"><font-awesome-icon size="3x"  :icon="['fab', 'bluesky']" /></b-button><br/>Bluesky</div>
              <!-- <div class="ml-2" style="text-align: center;"><b-button variant="primary" class="" target="X" :href="'https://twitter.com/intent/tweet?url=' + shareLink + '&text=' + shareText + '&via=visualthinkery&hashtags=' + this.appSettings.socialHashtags"><font-awesome-icon size="3x" :icon="['fab', 'x-twitter']" /></b-button><br/>Twitter</div> -->
              <div class="ml-2" style="text-align: center;"><b-button variant="primary" class="" target="Threads" :href="'https://www.threads.net/intent/post?url='+ shareLink +'&text=' + shareText"><font-awesome-icon size="3x" :icon="['fab', 'threads']" /></b-button><br/>Threads</div>
              <div class="ml-2" style="text-align: center;"><b-button variant="primary" class="" target="linkedIn" :href="'https://www.linkedin.com/sharing/share-offsite/?url=' + shareLink"><font-awesome-icon size="3x"  :icon="['fab', 'linkedin-in']" /></b-button><br/>LinkedIn</div>
              <div class="ml-2" style="text-align: center;"><b-button variant="primary" class="" target="linkedIn" :href="'https://shareopenly.org/share/?url=' + shareLink"><font-awesome-icon size="3x"  :icon="['fab', 'mastodon']" /></b-button><br/>Mastodon</div>
            </div>
          
          </div>

        <!-- </div> -->
      </div>
    </b-modal>
    
    <!-- DOWNLOAD Modal -->
    <b-modal  
      id="downloadRemixModal"
      @shown="downloadRemixModalInit"
      centered
      title="Download remix"
      body-bg-variant="dark"
      header-bg-variant="dark"
      footer-bg-variant="dark"
      ok-only
      ok-title="CLOSE"
      ok-variant="info">

      <!-- Download Remix -->
      <div class="card bg-dark" style="">
        <div class="card-body">
          
          <div class="text-center">
            <b-overlay :show="blnLoading" rounded="sm" variant="dark" opacity="0.5">
              <img id="myDLImage" :class="[(svgWidth >= svgHeight) ? 'responsive-width':'responsive-height']" :width="svgWidth" :height="svgHeight"  alt="Thinkery" ref="downloadPngImage" src="@/assets/knob.png" v-if="!(blnImageError && blnLoading)">
            </b-overlay>
          </div>

          <div v-if="!blnImageError" class="mt-3 flex-row d-flex justify-content-between">

            <div class="small"><h4>Download</h4>...an image of your Remix (or Touch-and-hold above image on Mobile).</div>

            <div  class="flex-row d-flex" >
              <div><b-button v-if="blnDownloadSVG" @click.prevent="downloadSVG" variant="primary" class="buttontext ml-2 text-center"><font-awesome-icon size="3x" fixed-width icon="file-download" /><br/>SVG</b-button></div>
              <div><b-button v-if="blnDownloadPNG" @click.prevent="downloadPNG" variant="primary" class="buttontext ml-2 text-center"><font-awesome-icon size="3x" fixed-width icon="file-download" /><br/>PNG</b-button></div>
            </div>

          </div>

          <div class="flex-row d-flex align-items-center" v-else>
            <div class="">
              <h1>Sorry!</h1>
              That didn't seem to work. :(</br>
              Some older browsers cough at this part. Maybe you could try Chrome or Firefox?
            </div>
          </div>


        </div>
      </div>
      
    </b-modal>
    
    <!-- PIN modal -->
    <b-modal  
      id="pinRemixModal"
      centered
      title="Pin Remix"
      body-bg-variant="dark"
      header-bg-variant="dark"
      footer-bg-variant="dark"
      ok-only
      ok-title="CLOSE"
      ok-variant="info">

      <!-- but only if there aren't any unsaved changes  -->
      <div class="card bg-dark" v-show="!blnImageError && !blnDirty">
        <div class="card-body">

          <!-- Show the Gallery Prompt option -->
          <div v-show="showPinRemix" class="row">
            <div class="col">
              <div class="d-flex justify-content-between">
                <div class="mr-3">
                    Set this Remix as the <b>Gallery Prompt</b>:
                </div>
                <div class="">
                  <b-form-checkbox v-model="blnGalleryPrompt" name="check-button" switch size="lg"></b-form-checkbox>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </b-modal>

    <!-- SAVE Modal -->
    <b-modal 
      id="saveRemixModal" 
      :title="(isUpdateRemix)? 'Update Your Remix' : 'Publish New Remix'" 
      ref="saveRemixModal" 
      body-bg-variant="dark"  
      header-bg-variant="dark" 
      footer-bg-variant="dark" 
      ok-title="Close"
      static centered ok-only 
      @ok="postSaveRemix"
      @shown="saveRemixModalInit" 
      @hidden="resetPublishedFlag">
      
      <!-- <p> Enjoying the Remixer Machine? <a class="badge badge-primary" href="https://patreon.com/remixer"  target="_blank"><font-awesome-icon  :icon="['fab', 'patreon']" /> Become a patron!</a></p> -->
      
      <!-- show alert if remix limit is reached! -->
      <div>
        <b-alert variant="danger" :show="remixLimitReached">Remix Limit Reached for this gallery!</b-alert>
      </div>

      <div class="text-center">
        <img id="myImage" :class="[(svgWidth >= svgHeight) ? 'responsive-width':'responsive-height']" :width="svgWidth" :height="svgHeight"  alt="Thinkery" ref="pngImage" src="@/assets/knob.png" v-if="!(blnImageError && blnLoading)">
      </div>

      <img id="myThumbImage" class="d-none" style="display:block;" :width="svgThumbWidth" :height="svgThumbHeight"  alt="Thinkery" ref="pngThumbImage" src="@/assets/knob.png">

      <!-- Publish -->
      <div v-show="!blnImageError ">

        <!-- User not logged in -->
        <div class="card bg-dark" style="" v-show="!$store.getters.isLoggedIn">
          <div class="card-body">
            
            <div class="flex-row d-flex  justify-content-center">
              <div style="align-self: center;">
                <h4 class="mb-0">Sign in</h4><span class="text-muted">with provider</span>
              </div>
              <div style="">
                <b-button variant="primary" @click.prevent="$store.dispatch('signUserInTwitter')" class="ml-2"><font-awesome-icon size="3x" fixed-width :icon="['fab', 'x-twitter']" /><br/>Twitter</b-button>
              </div>  
              <div style="">
                <b-button variant="primary" @click.prevent="$store.dispatch('signUserInGoogle')" class="ml-2"><font-awesome-icon size="3x" fixed-width :icon="['fab', 'google']" /><br/>Google</b-button>
              </div>
             
              <!-- <div class="ml-3 text-center"><a href="#" @click.prevent="$store.dispatch('signUserInTwitter')"><font-awesome-icon size="3x" :icon="['fab', 'x-twitter']" /></br>with</br>Twitter</a></div>
              <div class="ml-3 text-center"><a href="#" @click.prevent="$store.dispatch('signUserInGoogle')"><font-awesome-icon size="3x" :icon="['fab', 'google']" /></br>with</br>Google</a></div> -->
              
            </div>

          </div>
          
          <div class="card-body"  v-show="blnAllowAnonPosts" style="border-top:thick solid #555555;">
            
            <div class="flex-row d-flex  justify-content-center">
              <div class="text-muted">
                <p>Or sign in<br/>
                anonymously</p>
              </div>
              <div class="ml-2">
                <b-form @submit="onSubmitAnonForm">
                
                  <b-form-input 
                  id="newName" 
                  class="text-dark mb-2" 
                  style="background-color:#fff;" 
                  v-model="anonName" 
                  required
                  placeholder="Your Name..." ></b-form-input>
                  
                  <b-form-input 
                  id="newEmail" 
                  class="text-dark mb-2" 
                  style="background-color:#fff;" 
                  v-model="anonEmail" 
                  required
                  placeholder="Your Email..." ></b-form-input> 

                  <b-button type="submit"  variant="primary" class="">Save</b-button>
                </b-form>
              </div>

            </div>
            
            <!-- <div class="small"><h4>Sign in</h4>...to publish your Remix in the Remix Gallery so others can see it.</div> -->
          </div>
        </div>

        

        <!-- User logged in -->
        <div class="card bg-dark" style="background-color:#222222" v-show="$store.getters.isLoggedIn">

          <div class="card-body" v-show="allowPublish">
            <!-- <h4>{{(isUpdateRemix)? 'Update your Remix' : 'Publish New Remix'}}</h4> -->
            <div class="d-flex justify-content-end"><b-badge>Logged in as {{ $store.getters.userName }} ({{ $store.getters.provider }})</b-badge></div>

            <!-- Remixer's notes -->
            <div v-if="blnGallery">
              <p class="small text-muted mb-0">Remixer's notes:</p>
            
              <b-textarea 
                id="newComment" 
                max-rows="4" 
                class="text-dark" 
                style="background-color:#fff;" 
                v-model="newComment" 
                maxlength="250"
                placeholder="Add a comment about your remix..." ></b-textarea> 
            </div>

            <!-- Publish Button -->
            <div class="row mt-2" v-show="!blnPublished">
              <div class="col">
                <div class="d-flex justify-content-between">
                  <div class="small mr-3">
                    <p v-if="isUpdateRemix">Click the 'UPDATE' button to save changes to your remix<span v-show="blnGallery"> in this Gallery</span>.</p>
                    <p v-else>Click the 'PUBLISH' button to publish as a new remix<span v-show="blnGallery"> in this Gallery</span>.</p>
                    <!-- <p>So others can view (and remix) your Remix<span v-show="blnGallery"> in this Gallery</span>!</p> -->
                  </div>
                  <!-- <div v-show="!blnUpload && !blnPublished && blnDirty && blnLive" class="ml-auto text-center"><a ref="SS" href="#" @click.prevent="createRemix"><font-awesome-icon size="3x" icon="cloud" /></br>Publish <span v-if="blnPublished">again</span></a></div>
                  <div v-show="showUpdate" class="ml-auto text-center"><a ref="SS" href="#" @click.prevent="updateRemix"><font-awesome-icon size="3x" icon="save" /></br>Update</a></div> -->
                  <div class="d-flex flex-row">
                    <div><b-button v-show="showCreate && !isUpdateRemix" @click.prevent="createRemix" variant="primary" class="buttontext ml-2" :disabled="blnUpload"><font-awesome-icon size="3x" icon="file-upload" /><br/>PUBLISH</b-button></div>
                    <div><b-button v-show="showUpdate && isUpdateRemix" @click.prevent="updateRemix" variant="primary" class="buttontext ml-2" :disabled="blnUpload"><font-awesome-icon size="3x" icon="save" /><br/>UPDATE</b-button></div>
                    <div v-show="!showUpdate && !showCreate && !blnPublished" class="small align-self-center text-center ml-2">No&nbsp;Changes<br/>to&nbsp;Publish!</div>
                  </div>
                </div>
              </div>
            </div>
            
            
 
            <!-- upload progress bar -->
            <div class="row mt-2" v-show="blnUpload">
              <div class="col">
                  <div class="mt-2 mb-2"><b-progress :value="uploadProgress" :max="100" show-progress animated></b-progress></div>
              </div>
            </div>

            <!-- Share Feedback -->
            <div class="row mt-2" v-show="blnPublished">
              <div class="col">
                <b-alert v-if="isUpdateRemix" variant="success" show dismissible>Remix Updated!</b-alert>
                <b-alert v-else variant="success" show dismissible>New Remix Published!</b-alert>
              </div>
            </div>

          </div>

          <!-- Publish prevented -->
          <div class="card-body" v-show="!allowPublish">
            <h4>Zoiks!</h4>
            <p>Publishing on the public site is not possible because you are currently logged in with an anonymous profile.</p>
            <p>Please <a href="#" @click="$store.dispatch('logout')">logout</a> and you'll be able to login again using an authenticated profile.</p>
          </div>
        </div>
      </div>

      


      <!-- SHARE this Remix -->
      <!-- but only if there aren't any unsaved changes
      <div class="card bg-dark" v-show="!blnImageError && !blnDirty">
        <div class="card-body">

          <div class="row">
            <div class="col">
              <div class="mt-2">
                <b-tabs pills >
                  <b-tab active title-link-class="btn-outline-secondary">
                    <template slot="title" >
                      <font-awesome-icon class="mr-2" size="lg" :icon="['fab', 'x-twitter']" />Tweet
                    </template>
                    <div class="text-muted mt-2">


                      <social-sharing :url="shareLink"
                                :title="$route.meta.title"
                                :description="$route.meta.description"
                                quote="Remix this thinkery..."
                                :hashtags="this.appSettings.socialHashtags"
                                twitter-user="visualthinkery"
                                inline-template>


                          <div class="mr-0">Share this remix on Twitter: <a href="#" class="float-right"><network network="twitter"><font-awesome-icon size="3x" :icon="['fab', 'x-twitter']" /></network></a> <br/><span class="small">(opens pop-up window)</span></div>

                      </social-sharing>

                    </div>

                  </b-tab>

                  <b-tab title-link-class="btn-outline-secondary">
                    <template slot="title">
                      <font-awesome-icon class="mr-2" size="lg" :icon="['fa', 'link']" />Link
                    </template>
                    <div class="text-muted mt-2">Use this link to share on other networks:</div>
                    <div class="small" style="word-break: break-word;">{{shareLink}}</div>
                  </b-tab>

                  <b-tab title-link-class="btn-outline-secondary">
                    <template slot="title">
                      <font-awesome-icon class="mr-2" size="lg" :icon="['fa', 'code']" />Embed
                    </template>
                    <div class="text-muted mt-2">Paste this code in your blog:</div>
                    <div class="small mt-2" style="word-break: break-word;">{{embedCode}}</div>
                  </b-tab>
                </b-tabs>
              </div>



            </div>
          </div>

        </div>
      </div>  -->



      


      <!-- Regenerate Thumbnail -->
      <!-- <div class="card bg-dark" style=""  v-if="$store.getters.isAdmin && blnRemix && !blnDirty">
        <div class="card-body">



                <div class="flex-row d-flex justify-content-end float-right" >

                  <div class="ml-3 text-center"><a ref="regenTH" href="#" @click.prevent="regenThumbnail"><font-awesome-icon size="3x" icon="camera-retro" /><br/>Regen</a></div>

                </div>

                <div class="small"><h4>Regenerate</h4>...the thumbnail image stored.</div>






        </div>
      </div> -->


    </b-modal>

  </div>

</template>

<script>

import Vue from 'vue'
import download from 'downloadjs'
import VueClipboard from 'vue-clipboard2'
import {galleryFunctions} from '@/components/mixins/gallery'
import { dbFunctions } from '~/components/mixins/dbFunctions.js'
import SocialSharing from 'vue-social-sharing'
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

export default {
  name: 'SaveAndShare',
  components: {
    socialSharing: SocialSharing, VueClipboard,
  },
  inject: ['appRefs','appThings','appSettings','appRemix','appGallery'],
  mixins: [galleryFunctions,dbFunctions],
  props: {
    filenameBase: {
      default: "thinkery",
      type: String
    },
    defaultScale: {
      default: 1,
      type: Number
    },
    fileGuid: {
      default: "",
      type: String
    },
    comment: {
      default: "",
      type: String
    }

  },

  data () {
    return {
      svgObject: Object,
      svgHeight: 0,
      svgWidth: 0,
      svgThumbHeight: 0,
      svgThumbWidth: 0,

      blnUpload: false,
      uploadProgress: 0,
      blnPublished: false,
      urlPublishedImage: "",
      blnImageError: false,
      blnDownloadPNG: false,
      blnDownloadSVG: false,
      blnDirty: false,
      //blnRemix: (this.$route.params.id)? true : false,
      blnRemix: (this.appRemix.id)? true : false, // does the injected Remix Object exist?
      currentRemix: this.appRemix, // this is a local version of the injected remix object, which we may need to mutate
      blnGallery: (this.appGallery.id)? true : false, // does the injected Gallery Object exist?
      blnAllowAnonPosts: (this.appGallery.blnAllowAnonPosts)? true : false, // does the Gallery allow Anon posts (this might not be present)?
      blnLive: false, // when false prevents any remixes being published
      blnLoading: false,
      blnGalleryPrompt: false,
      remixBlurb: "",
      showCopiedToClipboard: false,

      isUpdateRemix: false,

      newComment: "",
      anonName: "",
      anonEmail: "",
      

      guid: "",

      // set up static refs to firebase
      remixCollectionRef: {},
      modelCollectionRef: {},
      //thumbCollectionRef: {},

    }
  },

  methods: {

    onCopy (e) {
      //alert('You just copied: ' + e.text)
      this.showCopiedToClipboard = true;
    },
    onError (e) {
      //alert('Failed to copy texts')
    },

    deselectAllThings(callback){
      //console.log("deselect", this.appThings);
      for (const key of Object.keys(this.appThings)) {
          //console.log(key, this.appThings[key]);
          if (this.appThings[key].selected){
            //console.log('found one',key)
            this.appThings[key].selected=0;
          }

      }
      callback();
    },

    createGuid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    },

    

    createRemix() {
      //console.log(this.appThings);

      var vm = this;
      this.blnUpload = true;

      // var parentRemixID = vm.$route.params.id ? vm.$route.params.id : ""
      var parentRemixID = this.blnRemix ? this.currentRemix.id : ""
      
      //construct new Model Object
      var newModelObj = {
        templateID: vm.appSettings.templateID,
        model: vm.appThings,
        created: vm.$fireModule.firestore.FieldValue.serverTimestamp(),
      }

      // upload the Model,
      vm.modelCollectionRef.add(newModelObj)
      .then(function(modelRef) {

        // add the thumbnail - call it the same name as the id of the new model so we can do a reverse lookup
        var thumbUploadPath = vm.$globalvars.thumbCollection + '/' + modelRef.id + ".png";

        // save thumbnail first and then with the callback, save the document JSON
        vm.saveStorage(vm.$refs.pngThumbImage.src, thumbUploadPath, "image/png", function(resourceURL){
          
          // thumbnail added
          //console.log('thumbnail added:', resourceURL)

          //construct new Remix Object
          var newRemixObj = {
            templateID: vm.appSettings.templateID,
            created: vm.$fireModule.firestore.FieldValue.serverTimestamp(),
            //path: vm.basePath,
            //routeName: vm.$route.name,
            thumbnail: resourceURL,
            thumbnailRef: thumbUploadPath,
            creator: vm.$store.state.user,
            parentRemixID: parentRemixID,
            comment: vm.newComment,
          }

          // if a gallery exists, add it too
          if (vm.appGallery.id){
            newRemixObj.galleryID = vm.appGallery.id;
          }

          //console.log ('adding a new remix:', newRemixObj)
          // upload the Remix Meta using the same Ref as the Model,
          vm.remixCollectionRef.doc(modelRef.id)
          .set(newRemixObj)
          .then(function(remixRef) {

            //console.log('successfully created new remix')
            // Store published information
            vm.blnPublished = true;
            vm.urlPublishedImage = resourceURL;

            //console.log("Document written with ID: ", docRef.id);
            vm.blnUpload = false;
            vm.blnDirty = false;

            // this is now a remix! - update currentRemix
            vm.currentRemix = newRemixObj;
            vm.currentRemix.id = modelRef.id;
            vm.blnRemix = true;
          })

        })

        
      })
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });
      
    },

    updateRemix(){

      // update a remix - this involves:
      // update the model with the new things.


      // check if the remix exists - can only update a remix if it already exists
      if (this.blnRemix){

        var vm = this;
        this.blnUpload = true;

        var thumbUploadPath = vm.$globalvars.thumbCollection + '/' + vm.currentRemix.id + ".png";

        // overwrite the existing thumbnail
        this.saveStorage(this.$refs.pngThumbImage.src, thumbUploadPath, "image/png", function(resourceURL){
          //console.log("Done. ThumbnailURL",resourceURL)

          // update the model with the new things object.
          vm.modelCollectionRef.doc(vm.currentRemix.id).update({
            model: vm.appThings,
          })
          .then(function() {
            //console.log ('successfully updated a remix with new model')

            // update the remix with an "updated date"
            vm.remixCollectionRef.doc(vm.currentRemix.id)
            .update({
              updated: vm.$fireModule.firestore.FieldValue.serverTimestamp(),
              comment: vm.newComment,
            })
            .then(function() {
              //console.log ('updated remix with timestamp')
            })
            .catch(function(error) {
              console.error("Error updating remix: ", error);
            });

            // Store published information
            vm.blnPublished = true;

            //console.log("Document written with ID: ", docRef.id);
            vm.blnUpload = false;
            vm.blnDirty = false;

          })
          .catch(function(error) {
            console.error("Error updating model: ", error);
          });

        })

      } else {
        console.log("Can't update - it's not a remix yet!");
      }

    },

    updateGalleryPrompt(){
      
      // if the remix is clean, provide the ability to make this remix the prompt for this gallery
      // is there a gallery, and is the user logged in?
      if ((this.appGallery.id) && (this.$store.getters.userID)){
        // does the person have the right credentials?
        if ((this.$store.getters.userID == this.appGallery.creator.id) || this.$store.getters.isAdmin ) {

          // update the gallery with empty remix details
          const galleryObj = {
            remixPromptID: '',
            remixPromptThumbURL: '',
          }

          // check that this is a remix and that it's clean
          if (this.blnRemix && !this.blnDirty && this.blnGalleryPrompt){
            // update the gallery with the details of this remix
            
            galleryObj.remixPromptID = this.currentRemix.id;
            galleryObj.remixPromptThumbURL = this.currentRemix.thumbnail;
            
            this.updateGallery(this.appGallery.id, galleryObj)

          } else {
            // update the gallery prompt fields with empty values
            
            this.updateGallery(this.appGallery.id, galleryObj)
          }

        } else {
          console.log ('user not authed to change this gallery')
        }
    
      } else {
        console.log ('there is no current gallery or user not logged in')
      }
    },


    regenThumbnail(){
      //console.log("Regenerate Thumbnail")

      // only run if dirty == false && if this is a remix
      if (this.blnRemix && !this.blnDirty){

        // overwrite the existing thumbnail
        this.saveStorage(this.$refs.pngThumbImage.src, this.currentRemix.thumbnailRef, "image/png", function(resourceURL){
          //console.log("Done. ThumbnailURL",resourceURL)
        })

      } else {
        console.log("not a clean remix, can't regenerate")
      }

    },


    resetPublishedFlag() {
      this.blnPublished = false;
      this.uploadProgress = 0;
    },



    saveStorage(src, name, mime, callback) {
      
      // Save a file (src) with a name, mimetype and callback

      function dataURItoBlob(dataURI, dataTYPE) {
          var binary = atob(dataURI.split(',')[1]), array = [];
          for(var i = 0; i < binary.length; i++) array.push(binary.charCodeAt(i));
          return new Blob([new Uint8Array(array)], {type: dataTYPE});
      }

      var file = dataURItoBlob(src,mime);

      //console.log(file);

      // Create the file metadata
      // Note: it would be good to store the RemixID here too - but it might not exist yet!
      var metadata = {
        contentType: mime,
        customMetadata: {
          //'remixID':( this.currentRemix.id )
          'creatorID': (this.blnRemix)? this.currentRemix.creator.id : this.$store.getters.userID,
          'templateID': this.appSettings.templateID, // always exists
          'galleryID': (this.appGallery.id)? this.appGallery.id : '', 
        }
      };

      // Upload file and metadata to the object
      // var uploadTask = storageRef.child(name).put(file, metadata);
      // TODO:does this work?
      var uploadTask = this.$fire.storage.ref().child(name).put(file, metadata);

      var vm = this;
      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(vm.$fireModule.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function(snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          vm.uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log('Upload is ' + vm.progress + '% done');
          switch (snapshot.state) {
            case vm.$fireModule.storage.TaskState.PAUSED: // or 'paused'
              //console.log('Upload is paused');
              break;
            case vm.$fireModule.storage.TaskState.RUNNING: // or 'running'
              //console.log('Upload is running');
              break;
          }
        }, function(error) {
          console.log('File Upload error:',error)
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;

          case 'storage/canceled':
            // User canceled the upload
            break;

          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      }, function() {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          callback(downloadURL)

          //console.log('File available at', downloadURL);
        });
      });
    },

    initCanvas() {
      //console.log("initCanvas",this.svgObject);
      // take SVG sizes from the SVG itself
      this.svgWidth = this.defaultScale * this.svgObject.viewBox.baseVal.width;
      this.svgHeight = this.defaultScale * this.svgObject.viewBox.baseVal.height;

      this.svgThumbWidth = this.appSettings.thumbWidth;

      // calculate the thumbnail sizes (thumbnail width is set above)
      this.svgThumbHeight = Math.floor(this.svgHeight*this.svgThumbWidth/this.svgWidth);


      // initialise settings
      this.blnDownloadPNG = this.appSettings.downloads.includes("PNG");
      this.blnDownloadSVG = this.appSettings.downloads.includes("SVG");
    },

    

    drawInlineSVG(svgElement, ctx, width, height, canvas, imgPlaceholder, callback) {
      //console.log("drawInlineSVG");

      //console.log(imgPlaceholder)

      //this.initCanvas();

      var svgURL = (new XMLSerializer()).serializeToString(svgElement);

      var myImg  = new Image(width, height);
      //myImg.crossOrigin = "Anonymous";

      var vm = this;

      vm.blnImageError = false;

      myImg.onload = function(){
        //console.log("onloadimage function called");
        try {


          // clear Canvas
          ctx.clearRect(0, 0, width, height);
          // draw SVG contents on canvas
          ctx.drawImage(this, 0, 0, width, height);

          //var canvasUrl = canvas.toDataURL("image/png");
          //imgPlaceholder.src = canvasUrl;
          // try direct assignment instead.
          imgPlaceholder.src = canvas.toDataURL("image/png");

          //console.log("This image is fine.",myImg.src);
        } catch (e) {
          console.log(e);
          vm.blnImageError = true;
        }

        //console.log("calling back");
        callback();
      }
      myImg.onerror = function(e) {
        console.log("This image is not ok, I'm afraid.",e);
        //console.log("This image is not ok, I'm afraid.",myImg.src);
        vm.blnImageError = true;
      }

      //console.log('writing out the image');
      myImg.src = "data:image/svg+xml; charset=utf8, "+encodeURIComponent(svgURL);
      
      //myImg.src = require("@/assets/remixer_sorry_notice.svg")
      //vm.blnImageError = true;
    },

    downloadSVG() {
      //const svgData = this.$refs.figure.outerHTML;
      //console.log(this.svgObject);
      var source = (new XMLSerializer()).serializeToString(this.svgObject);
      download(source, this.filenameBase + ".svg", "image/svg+xml");

    },

    downloadPNG() {
      //console.log("downloadPNG");
      const myImage = this.$refs.downloadPngImage;
      download(myImage.src, this.filenameBase + ".png", "image/png");
    },

    downloadRemixModalInit() {
      //console.log("downloadRemixModalInit");

      this.blnLoading = true;

      // reinitialise the dimensions of the canvas, as it might have changed.
      this.initCanvas()

      const myCanvas = this.$refs.pngCanvas;
      const myImage = this.$refs.downloadPngImage;
      var vm = this;

      //switch off any controls first
      this.deselectAllThings(function(){
        // draw the canvas
        vm.drawInlineSVG(vm.svgObject, myCanvas.getContext('2d'), vm.svgWidth, vm.svgHeight, myCanvas, myImage, function(){
          // draw the canvas AGAIN! (this is for Safari...)
          vm.drawInlineSVG(vm.svgObject, myCanvas.getContext('2d'), vm.svgWidth, vm.svgHeight, myCanvas, myImage, function(){
            //console.log('finished drawing the main image');

            //update the loading boolean
            vm.blnLoading = false;

          });
        });
      })
    },


    saveRemixModalInit() {
      //console.log("saveRemixModalInit");
      // create the PNG

      this.blnLoading = true;

      // reinitialise the dimensions of the canvas, as it might have changed.
      this.initCanvas()

      const myCanvas = this.$refs.pngCanvas;
      const myThumbCanvas = this.$refs.pngThumbCanvas;
      const myImage = this.$refs.pngImage;
      const myThumbImage = this.$refs.pngThumbImage;
      var vm = this;

      //console.log('myCanvas',myCanvas)
      //console.log('myThumbCanvas',myThumbCanvas)
      //console.log('myImage',myImage)
      //console.log('myThumbImage',myThumbImage)

      //switch off any controls first
      this.deselectAllThings(function(){
        // draw the canvas
        vm.drawInlineSVG(vm.svgObject, myCanvas.getContext('2d'), vm.svgWidth, vm.svgHeight, myCanvas, myImage, function(){
          // draw the canvas AGAIN! (this is for Safari...)
          vm.drawInlineSVG(vm.svgObject, myCanvas.getContext('2d'), vm.svgWidth, vm.svgHeight, myCanvas, myImage, function(){
            //console.log('finished drawing the main image');
            // create the thumbnail
            vm.drawInlineSVG(vm.svgObject, myThumbCanvas.getContext('2d'), vm.svgThumbWidth, vm.svgThumbHeight, myThumbCanvas, myThumbImage, function(){
              //console.log('finished drawing the thumb image');

              //update the loading boolean
              vm.blnLoading = false;

            });

          });
        });
      })

    },

    showSaveRemixModal(isUpdate){
      this.isUpdateRemix = isUpdate;
      if(isUpdate){
        // copy the comment that already exists
        this.newComment = this.comment
      } else {
        // make sure the comment is blank
        this.newComment = ''
      }
      // open save modal
      this.$bvModal.show('saveRemixModal')
    },

    confirmDeleteRemix(){
      //console.log('confirmed delete remix')
      this.removeRemix(this.currentRemix.id, this.currentRemix.galleryID)
      

      
      if (this.blnGallery){
        // if this is a gallery -> go to the gallery home.
        this.$router.push({ name: 'g-gid', params: { gid: this.appGallery.id } })
      }else{
        // if this is not a gallery -> go to the template home.
        this.$router.push({ name: 'a-app-id', params: { app: this.appSettings.templateID } })
      }
      

    },

    postSaveRemix(){
      //console.log('new / updated remix')

      if (this.blnPublished){
        if ( !this.isUpdateRemix){
          // a new remix has been uploaded/updated. Go to the new remix!
          this.$router.push({ name: 'r-rid-gid', params: { rid: this.currentRemix.id, gid: this.appGallery.id } })
        }else{
          // updated remix - so make sure comment updates are propogated back up to the parent
          this.$emit('update:comment', this.newComment)
        }
        
      }
    },

    onSubmitAnonForm(event) {
      event.preventDefault()
      this.$store.dispatch('signUserInAnonymous',{displayName:this.anonName,email:this.anonEmail})
    },


  },
  computed: {
    // if the remix has been published, then the sharelink will have changed
    shareLink: function () {
      if (this.blnPublished){
        return process.env.baseUrl + this.$router.resolve({
          name: 'r-rid-gid',
          params: {rid:this.currentRemix.id, gid: this.currentRemix.galleryID},
        }).href
      } else {
        return process.env.baseUrl + this.$route.path
      }
    },

    shareText: function () {
      var text = 'My Remix \nvia The Remixer Machine.\n'
      return encodeURIComponent(text)
    },

    shareHashTags: function () {
      var text = ''
      var arrHashtags = this.appSettings.socialHashtags.split(',')
      arrHashtags.forEach((keyword) => text = text + '#' + keyword + ' ')
      return encodeURIComponent(text)
    },
    

    embedCode: function() {
      var ratio = Math.floor(100 * this.svgHeight/this.svgWidth);
      var url = this.shareLink + '?embed=true';
      var html =
      '<div style="position: relative; padding-top: ' + ratio + '%;">' +
        '<iframe style="position:absolute; top:0; left:0; width:100%; height:100%; border:0;" src="' + url + '" allowtransparency="true" name="Remixer machine" scrolling="no" frameborder="1" marginheight="0px" marginwidth="0px"  allowfullscreen></iframe>' +
      '</div>';

      return html
    },

    remixLimitReached: function(){
      if (this.blnGallery){
        return (this.appGallery.numRemixes >= this.appGallery.maxRemixes)
      }else{
        return false;
      }
       
    },

    // Should we should the Update option?
    showUpdate: function() {
      var show = false;
      if (!this.blnImageError && this.blnLive){
          if (this.blnRemix && this.$store.getters.isLoggedIn && this.$store.getters.allowEditRemix){ // does the creator exist (is it a remix?)
            if ((this.$store.getters.userID == this.currentRemix.creator.id) || (this.$store.getters.userID == this.appGallery.ownerID) || this.$store.getters.isAdmin ){ // are you the creator? or Curator? or Admin?
              show = true;
            }
          }
      }
      return show
    },

    // Should we should the Delete option?
    showDelete: function() {
      var show = false;
      if (!this.blnImageError && this.blnLive){
          if (this.blnRemix && this.$store.getters.isLoggedIn){ // does the creator exist (is it a remix?)
            if ((this.$store.getters.userID == this.currentRemix.creator.id) || (this.$store.getters.userID == this.appGallery.ownerID) || this.$store.getters.isAdmin ){
              show = true;
            }
          }
      }
      return show
    },

    showCreate: function() {
      var show = false;
      if (!this.blnImageError && this.blnDirty && this.blnLive && !this.blnPublished && this.$store.getters.isLoggedIn){
        show = true;
      }
      return show
    },

    allowPublish: function() {
      var allow = false;
      // if properly logged in OR if anonymous is allowed
      if ((this.$store.getters.isLoggedIn && !this.$store.getters.isAnonymousLoggedIn) || (this.$store.getters.isAnonymousLoggedIn && this.blnAllowAnonPosts)){
        allow = true;
      }
      return allow
    },

    // Should we show the "set as prompt"
    showPinRemix: function() {
      var show = false;
      if (!this.blnImageError && !this.blnDirty && this.blnLive){ // no error, any changes have been saved, live app
          if (this.blnGallery && this.$store.getters.isLoggedIn && this.$store.getters.allowCustomPrompt){ // is there a gallery, and is the user logged in?
            if ((this.$store.getters.userID == this.appGallery.creator.id) || this.$store.getters.isAdmin ){ // is this the gallery creator or superadmin?
              show = true;
            }
          }
      }
      return show
    },
  },

  watch: {
    'appThings': {
      handler: function (val, oldVal) {
        this.blnDirty = true;
      },
      deep: true
    },
    // 'blnGalleryPrompt':{
    //   handler: function (val, oldVal) {
    //     this.updateGalleryPrompt();
    //   }
    // },
  },

  created (){
    // have a look to see if this app is live or dev
    this.blnLive = (this.appSettings.status == "live")? true : false;

    // find out whether this remix is the prompt for the gallery
    if (this.blnRemix && this.blnGallery){
      this.blnGalleryPrompt = (this.appGallery.remixPromptID == this.currentRemix.id);
    }

    // manually add a watcher to detect when the blnGalleryPrompt has been updated
    this.$watch('blnGalleryPrompt', function(newValue, oldValue) {
      this.updateGalleryPrompt();
    });

    // set up the Collection References
    if (this.blnGallery){
      this.remixCollectionRef = this.$fire.firestore.collection(this.$globalvars.galleryCollection).doc(this.appGallery.id).collection('remixes')
      this.modelCollectionRef = this.$fire.firestore.collection(this.$globalvars.galleryCollection).doc(this.appGallery.id).collection('models')
      //this.thumbUploadPath  = this.$globalvars.thumbCollection + "/galleries/" + this.appGallery.id + "/thumbs/" + this.filenameBase + '-' + this.createGuid() + '.png' ;
      //this.thumbUploadPath  = this.$globalvars.thumbCollection;
    } else {
      this.remixCollectionRef = this.$fire.firestore.collection(this.$globalvars.remixCollection)
      this.modelCollectionRef = this.$fire.firestore.collection(this.$globalvars.modelCollection)
      //this.thumbUploadPath  = this.$globalvars.thumbCollection;
      //this.thumbUploadPath  = this.$globalvars.thumbCollection + "/" + this.filenameBase + '-' + this.createGuid() + '.png' ;
    }
    
  },

  beforeMount: function(){
    var vm = this;
    this.$root.$on('showSaveAndShareModal', function(){
      //console.log(`Root call for showing Save and Share Modal`)

      // show the modal
      this.$nextTick(() => {
        // The whole view is rendered, so I can safely access or query
        // the DOM. ¯\_(ツ)_/¯
        vm.$bvModal.show('saveRemixModal')
      })
    });
  },

  mounted () {
    //console.log(this.svgObject);
    //
    this.svgObject = this.appRefs.svgObject;

    //console.log(this.appRefs.svgObject)

    // initialise the canvas (and thumb canvas) based on the current size of the SVG
    // this.initCanvas();

    // to get around Safari
    //this.createPNG();

    // init the background color of the SVG from prop settings
    // this.svgObject.style.backgroundColor = this.imageBackgroundColor;


    // if no guid has been passed in, create one.
    //if (this.fileGuid == "") { this.guid = guid() }else{ this.guid = this.fileGuid}
  },

}



</script>



<style>

.modal-content {
  background-color: transparent;
}
button.close {
  color:#fff;
}

.modal-header {

  border-bottom-color: #555;
}

.modal-footer {
  border-top-color: #555;
}

.responsive-height {
  max-height:80vh;
  max-width:100%;
  height:auto;
  width:auto;
  /* width:auto; */
}

.responsive-width {
  max-width:100%;
  height:auto;
}

.simple-icon svg {
  fill: #fff;
}

.remixer-header {
  background-color: #fff;
  color: #555;
  padding-left:1em;
  padding-right:1em;
}

mark {
  background-color: #de4500;
  color: white;
}

.buttontext {
  font-weight: 800;
  line-height: 1.8em;
  letter-spacing: 0.2em;
  font-size:12px;
}



</style>
